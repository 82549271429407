import React, { useContext } from "react";

const IconButtonGroupContext = React.createContext();

export const IconButtonGroup = ({ children, className = "" }) => {
    return (
        <div
            className={
                "border border-gray-400 flex items-center rounded-xl overflow-hidden " +
                className
            }
        >
            <IconButtonGroupContext.Provider value={{ group: true }}>
                {children}
            </IconButtonGroupContext.Provider>
        </div>
    );
};

export const IconButton = ({
    children,
    onClick,
    selected,
    className,
    disabled = false,
}) => {
    const c = useContext(IconButtonGroupContext);
    const group = c?.group;

    return (
        <button
            className={`${selected ? "bg-gray-300" : ""}  ${
                group ? "" : "hover:rounded-full"
            } p-2 ${
                disabled ? "text-gray-400" : "hover:text-primary-100 hover:bg-primary-500"
            } ${className}`}
            onClick={onClick}
            disabled={disabled}
        >
            {children}
        </button>
    );
};
