export const communityTypes = [
    {
        value: "IL",
        name: "Independent Living (IL)",
        help: "This community offers housing and ancillary services for seniors who can live independently without any help with personal care or health.  It may also be a type of living offered in an RCFE or CCRC.",
    },
    {
        value: "AL",
        name: "Assisted Living (AL)",
        help: "This community is licensed with their state as an RCFE. It can be either a residential home or a congregate community.  It is a non-clinical setting providing assistance with the activities of daily living. It is staffed 24/7 to meet health and personal care needs.  Nursing oversight varies by community.",
    },
    {
        value: "MC",
        name: "Memory Care (MC)",
        help: "This community is licensed with their state as an RCFE for residents with a diagnosis of dementia or memory impairment. It can be either a residential home or a congregate community.  It is a non-clinical setting helping with the activities of daily living. It is staffed 24/7 with caregivers who are required to be specifically trained to meet the needs of persons with memory loss.  Nursing oversight varies by community.",
    },
    /*{
        value: "ALW",
        name: "Assisted Living Waiver (ALW)",
    },*/
    {
        value: "Respite",
        name: "Respite",
        help: "A short term stay so a loved one can get rest & relief or a short trial period to test living in a community.",
    },
    {
        value: "Hospice",
        name: "Hospice",
        help: "Compassionate care for people with a terminal diagnosis. Personal care and health needs may be provided by community staff and/or an outside organization.",
    },
    {
        value: "RCFE",
        name: "Residential Care Facilities for the Elderly (RCFE)",
        help: "Residential Community for the Elderly. This community is licensed with their state and can be either a residential home or multilevel. It is a non-clinical setting that helps with the activities of daily living. It is staffed 24/7 to meet health and personal care needs. Nursing oversight varies by community.",
    },
    {
        value: "CCRC",
        name: "Continuing Care Retirement Community (CCRC)",
        help: "Continuing Care Retirement Community - This community is licensed with their state. It offers the continuum of independent living, assisted living, memory care and skilled nursing usually on one campus or location.  The resident has an option to buy into a life-time care contract assuring them of care through the end of life.",
    },
    {
        value: "NH",
        name: "Nursing Home (NH)",
        help: "This community is licensed with their state to provide skilled nursing on a short- or long-term basis in a clinical setting.  They may also have rehabilitation available.",
    },
    {
        value: "Medicare",
        name: "Medicare",
        help: "This community may be able to accept Medicare if they are contracted with your specific Medicare health plan.",
    },
    {
        value: "Medicaid",
        name: "Medicaid (Medi-Cal) Waiver Program",
        help: "This community accepts Medicaid and Medi-Cal.  Medicaid is a joint state and federal assistance program for adults with low income.",
    },
    /*{
        value: "Medi-Cal",
        name: "Medi-Cal Waiver Program",
        help: "This community accepts Medi-Cal.  Medi-Cal is the name used in California for Medicaid. Medi-Cal is a joint state and federal assistance program for adults with low income.",
    },*/
    {
        value: "LTC",
        name: "Long Term Care (LTC)",
        help: "This community may be able to accept your long-term care insurance.  Long term care insurance policies are purchased insurance plans for assisted living and memory care.",
    },
    {
        value: "Private Pay",
        name: "Private Pay",
        help: `The resident will pay for their rent, care, and services with personal resources or “out of pocket”.`,
    },
    {
        value: "Managed Care Contracts",
        name: "Managed Care Contracts",
        help: "This community is a network provider for managed health care plans – contact the community regarding specific insurance plans.",
    },
    {
        value: "Residential Care Home",
        name: "Residential Care Home",
        help: "Residential Care Homes are private homes in residential areas that have been converted to senior living, assisted living, and or memory care. Most care homes have three bedrooms and 2 bathrooms and have six beds - one to two persons per bedroom and shared bathrooms. You may find even larger communities based on the size of the home and how many bedrooms & bathrooms it has. Maximum occupancy may vary state by state, and a state licensing agency oversees them if they help with personal care and assistance.",
    },
    {
        value: "IHCS",
        name: "In-Home Care Services (IHCS)",
        help: "An agency that seniors can hire to provide the placement of in-home caregivers who help with the activities of daily living or ADLs.  These tasks may include dressing, grooming, meal preparation, medication oversight, transportation, shopping, light housekeeping, and general companionship.",
    },
    {
        value: "Skilled Nursing",
        name: "Skilled Nursing",
        help: "Skilled nursing care is provided by trained registered nurses under a doctor’s supervision in a medical setting. It’s basically the same level of nursing care you get in the hospital.",
    },
    {
        value: "Rehabilitation Facility",
        name: "Rehabilitation Facility",
        help: "A medical rehabilitation facility/hospital, also known as an inpatient rehabilitation facility, is a specialty hospital that focuses on treating people recovering from debilitating injuries, illnesses, surgeries, and chronic medical conditions.",
    },
    {
        value: "Retirement Living",
        name: "Retirement Living",
        help: "A retirement community is a residential community or housing complex designed for older adults who are generally able to care for themselves; however, assistance from home care agencies is allowed in some communities, and activities and socialization opportunities are often provided. Some characteristics typically are: the community must be age-restricted, age-qualified residents must be partially or fully retired, and the community offers shared services or amenities.",
    },
    {
        value: "Personal Care Home",
        name: "Personal Care Home",
        help: "They are also known as residential or adult care homes, and most of their inhabitants have private or semi-private rooms. Such houses are frequently (not always) operated by a family, with members living on-site.",
    },
    {
        value: "Board and Care Home",
        name: "Board and Care Home",
        help: "Board and care homes are houses in residential neighborhoods that are equipped and staffed to care for a small number of residents, usually between two and ten. Depending on licensing, these homes may provide comparable care to what’s offered at assisted living communities.",
    },
    {
        value: "Adult Foster Care Home",
        name: "Adult Foster Care Home",
        help: "Adult Foster Care Homes are licensed single-family residences that offer 24-hour care in a homelike setting to adults and adults with physical and sometimes mental disabilities. A wide variety of adults are served in adult foster homes, from those only needing room and board to those needing full personal care.",
    },
    {
        value: "Adult Group Home",
        name: "Adult Group Home",
        help: "A group home is a living accommodation option for people with disabilities. Living in a group home may be a good option for those individuals who do not need advanced medical care but cannot safely live alone.",
    },
];

export const communityAvailability = [
    {
        value: "Not Available",
        name: "Not Available",
    },
    {
        value: "Available Now",
        name: "Available Now (Vacancy)",
    },
    {
        value: "Coming Soon",
        name: "Coming Soon",
    },
];

export const depositTypes = [
    {
        value: "Deposit Required",
        name: "Deposit Required",
    },
    {
        value: "Deposit Not Required",
        name: "Deposit Not Required",
    },
];

export const careFeeTypes = [
    {
        value: "Care Fee Included in Rent",
        name: "Care Fee Included in Rent",
    },
    {
        value: "Care Fee Not Included in Rent",
        name: "Care Fee Not Included in Rent",
    },
    {
        value: "Care Fee Determined by Unit",
        name: "Care Fee Determined by Unit",
    },
];

export const communityFeeTypes = [
    {
        value: "Community Fee Required",
        name: "Community Fee Required",
    },
    {
        value: "Community Fee Not Required",
        name: "Community Fee Not Required",
    },
];

export const statusTypes = [
    {
        value: "None",
        name: "None",
    },
    {
        value: "New",
        name: "New",
    },
    {
        value: "Available Now",
        name: "Available Now",
    },
    {
        value: "Coming Soon",
        name: "Coming Soon",
    },
];

export const parkingTypes = [
    {
        value: "Open Outdoor",
        name: "Open Outdoor",
    },
    {
        value: "Open Garage",
        name: "Open Garage",
    },
    {
        value: "Reserved Uncovered",
        name: "Reserved Uncovered",
    },
    {
        value: "Reserved Covered",
        name: "Reserved Covered",
    },
    {
        value: "Reserved Garage",
        name: "Reserved Garage",
    },
    {
        value: "Electric Charging",
        name: "Electric Charging",
    },
];

export const propertySmokingTypes = [
    {
        value: "On Site",
        name: "On Site",
    },
    {
        value: "Off Site",
        name: "Off Site",
    },
];

export const careNurseTypes = [
    {
        value: "None",
        name: "None",
    },
    {
        value: "RN",
        name: "Registered Nurse (RN)",
        help: "Registered nurses have graduated from a nursing program and have met the licensing requirements of their state. Typically a registered nursing college is a four year program. They may coordinate and provide care for a patient.  Each state requires the candidate to pass board exams and once licensed, satisfy continuing education requirements.  The duties permitted to be performed may be determined by both the state license restrictions and employing facility or community.",
    },
    {
        value: "LVN",
        name: "Licensed Vocational Nurse (LVN)",
        help: "This title may be used interchangeably with Licensed Practical Nurse in some states.  Vocational nursing programs may be offered in a community college setting or in a technical or trade school setting.  Typically this is a 2 year program. Each state requires the candidate to pass board exams and once licensed, satisfy continuing education requirements.  The duties permitted to be performed may be determined by both the state license restrictions and employing facility.  As an example, in most states, LVNs cannot administer IV medications, but can give injections.",
    },
    {
        value: "Caregiver",
        name: "Caregiver",
        help: "A person who provides direct care to another person.  In senior living, a caregiver is a staff member of the community who provides service and support to a resident.  The caregiver assists the resident with their activities of daily living and their personal care needs.",
    },
    {
        value: "Med-Tech",
        name: "Med-Tech",
        help: "A med-tech usually refers to an employee in an assisted living community who assists the residents with self-administration of their medications. Generally this is an unlicensed position but requires state-specific training and oversight.",
    },
    {
        value: "Medical Director",
        name: "Medical Director",
        help: "The scope of a Medical Director will vary depending on the type of community you are researching. In a clinical setting such as a skilled nursing facility, a Medical Director provides oversight to nursing staff regarding individuals requiring specific medical care, such as writing orders for medication changes and treatment plans.  In an assisted living setting, this role may be more of an advisory role to nursing and care staff and support and education for residents.",
    },
    {
        value: "Telehealth",
        name: "Telehealth",
        help: "Telehealth can cover a broad spectrum of education and services through technology.  It includes but is not limited to video visits with medical staff, on-demand streaming of health education, and device-specific communication to healthcare providers.  An example would be a blood pressure monitor that sends readings directly to a resident's primary care provider.",
    },
    {
        value: "Visiting Physician",
        name: "Visiting Physician",
        help: "Check with the community regarding the level of services offered",
    },
    {
        value: "Visiting Nurse",
        name: "Visiting Nurse",
        help: "Check with the community regarding the level of services offered",
    },
    {
        value: "Licensed Practical Nurse",
        name: "Licensed Practical Nurse",
        help: "A licensed practical nurse typically performs basic medical tasks, including checking vital signs and feeding patients. Check with the community regarding the level of services offered.",
    },
];

export const careNurseHoursTypes = [
    {
        value: "24 Hours",
        name: "24 Hours",
    },
    {
        value: "Business Hours",
        name: "Business Hours",
    },
];

export const careLiftTypes = [
    {
        value: "No",
        name: "No",
    },
    {
        value: "Single Person Assist",
        name: "Single Person Assist",
    },
    {
        value: "Two Person Assist",
        name: "Two Person Assist",
    },
    {
        value: "Hoyer Lift",
        name: "Hoyer Lift",
    },
];

export const careOptionsTypes = [
    {
        value: "Physical Therapy",
        name: "Physical Therapy",
        help: "Physical Therapy: The treatment of disease, injury, or deformity by physical modalities and procedures such as massage, heat treatment, and exercise.",
    },
    {
        value: "Speech Therapy",
        name: "Speech Therapy",
        help: "Speech Therapy: Training to help people with speech and language problems to speak more clearly.",
    },
    {
        value: "Occupational Therapy",
        name: "Occupational Therapy",
        help: "Occupational Therapy: A form of therapy for residents recovering from physical or mental illness that encourages rehabilitation through the performance of activities required in daily life.",
    },
    {
        value: "Respiratory Therapy",
        name: "Respiratory Therapy",
        help: "Respiratory Therapy:  Is a type of health care specializing in promoting optimum cardiopulmonary function.s",
    },
    {
        value: "Social Services",
        name: "Social Services",
        help: "Social Services: A type of activity aiming to promote the welfare of others.",
    },
    {
        value: "Behavioral Health Services",
        name: "Behavioral Health Services",
        help: "Behavioral Health Services: Helping residents with a spectrum of behaviors and conditions comprising mental health, substance use disorders, and problem gambling.",
    },
    {
        value: "Medical Supplies",
        name: "Medical Supplies",
        help: "Medical Supplies: A piece of durable medical equipment or an expendable medical supply such as incontinence supplies.",
    },
    {
        value: "IV Therapy",
        name: "IV Therapy",
        help: "IV Therapy: A medical technique that administers fluids, medications and nutrients directly into a person's vein.",
    },
    {
        value: "Terminal Care",
        name: "Terminal Care",
        help: "Terminal Care: Treatment provided to a critically ill person in a situation where curative treatment has been discontinued. Dying patients are not left to cope with their condition even though the progress of their disease can no longer be influenced.",
    },
    {
        value: "Wound Care",
        name: "Wound Care",
        help: "Wound Care: Specific types of treatment for pressure sores , skin ulcers and other wounds that break the skin.",
    },
    {
        value: "Enteral Nutrition",
        name: "Enteral Nutrition",
        help: "Enteral Nutrition: A form of nutrition that is delivered into the digestive system as a liquid. Drinking nutritional beverages or formulas and tube feeding are forms of enteral nutrition.",
    },
    {
        value: "Parenteral Therapy",
        name: "Parenteral Therapy",
        help: "Parenteral Therapy: A form of nutrition that is delivered into a vein. Parenteral nutrition does not use the digestive system. It may be given to people who are unable to absorb nutrients through the intestinal tract because of vomiting that won't stop, severe diarrhea, or intestinal disease.",
    },
    {
        value: "Ostomy Care",
        name: "Ostomy Care",
        help: "Ostomy Care: Maintenance of elimination of bodily waste through a stoma and the care of the surrounding area.",
    },
    {
        value: "Intravenous Therapy",
        name: "Intravenous Therapy",
        help: "Intravenous Therapy: A way to give fluids, medicine, nutrition, or blood directly into the bloodstream through a vein.",
    },
    {
        value: "Injectable Medication",
        name: "Injectable Medication",
        help: "Injectable Medication: A drug that can be put into the body using a needle and syringe.",
    },
    {
        value: "Diabetic Program",
        name: "Diabetic Program",
        help: "Diabetic Program: High-quality lifestyle change programs to reduce their risk of type 2 diabetes and improve their overall health.",
    },
    {
        value: "Oxygen Management",
        name: "Oxygen Management",
        help: "Oxygen Management: A storage tank of oxygen or a machine called a compressor is used to give oxygen to people with breathing problems. It may be given through a nose tube, a mask, or a tent.",
    },
    {
        value: "Medication Management",
        name: "Medication Management",
        help: "Check with the community regarding the level of services offered.",
    },
    {
        value: "Medication Assistance",
        name: "Medication Assistance",
        help: "Check with the community regarding the level of services offered.",
    },
    {
        value: "Incontinence Care",
        name: "Incontinence Care",
        help: "Check with the community regarding the level of services offered.",
    },
    {
        value: "Bathing & Dressing",
        name: "Bathing & Dressing",
        help: "Check with the community regarding the level of services offered.",
    },
    {
        value: "Activities of Daily Living",
        name: "Activities of Daily Living",
        help: "Check with the community regarding the level of services offered.",
    },
    {
        value: "Personal Care",
        name: "Personal Care",
        help: "Check with the community regarding the level of services offered.",
    },
    {
        value: "Mobility",
        name: "Mobility",
        help: "Check with the community regarding the level of services offered.",
    },
    {
        value: "Toileting",
        name: "Toileting",
        help: "Check with the community regarding the level of services offered.",
    },
    {
        value: "Rehabilitation Services",
        name: "Rehabilitation Services",
        help: "Check with the community regarding the level of services offered.",
    },
];

export const securityTypes = [
    {
        value: "Courtesy Patrolled",
        name: "Courtesy Patrolled",
    },
    {
        value: "Unguarded Gate",
        name: "Unguarded Gate",
    },
    {
        value: "Guarded Gate",
        name: "Guarded Gate",
    },
    {
        value: "Video Surveillance ",
        name: "Video Surveillance",
    },
];

export const careInjectionsTypes = [
    {
        value: "No",
        name: "No",
    },
    {
        value: "Oversight",
        name: "Oversight",
        help: "The action of overseeing a resident self-test (diabetes) and or self-inject a medication.",
    },
    {
        value: "Injections",
        name: "Injections",
    },
];

export const foodPreparationTypes = [
    {
        value: "None",
        name: "None",
    },
    {
        value: "Batch Cooking",
        name: "Batch Cooking",
        help: "Batch Cooking: is when a cook makes a lot of a specific food all at once, then stores it in portions for later use.",
    },
    {
        value: "Cooked To Order",
        name: "Cooked To Order",
    },
    {
        value: "Mechanical/Pureed",
        name: "Mechanical/Pureed",
        help: "Mechanical/Pureed: The use of equipment for grinding, crushing, cutting, and forming food for easier chewing and swallowing.",
    },
];

export const foodOptionsTypes = [
    {
        value: "Organic",
        name: "Organic",
    },
    {
        value: "Vegetarian",
        name: "Vegetarian",
    },
    {
        value: "Vegan",
        name: "Vegan",
    },
    {
        value: "Dietary Restrictions",
        name: "Dietary Restrictions",
    },
    {
        value: "Gluten Free",
        name: "Gluten Free",
    },
    {
        value: "Dairy Free",
        name: "Dairy Free",
    },
    {
        value: "Allergies",
        name: "Allergies",
    },
    {
        value: "Sugar Free",
        name: "Sugar Free",
    },
    {
        value: "Heart Healthy",
        name: "Heart Healthy",
    },
    {
        value: "Diabetic",
        name: "Diabetic",
    },
    {
        value: "Kosher",
        name: "Kosher",
        help: "Kosher: A term used to describe food that complies with the strict dietary standards of traditional Jewish law.",
    },
    {
        value: "Halal",
        name: "Halal",
        help: "Halal: Denoting or relating to meat prepared as prescribed by Muslim law.",
    },
    {
        value: "Specialized Dietary Services",
        name: "Specialized Dietary Services",
    },
];

export const amenitiesTypes = [
    {
        value: "None",
        name: "None",
    },
    {
        value: "Theater",
        name: "Theater",
    },
    {
        value: "Art Studio",
        name: "Art Studio",
    },
    {
        value: "Salon Spa",
        name: "Salon Spa",
    },
    {
        value: "Hairstylist",
        name: "Hairstylist",
    },
    {
        value: "Manicure",
        name: "Manicure",
    },
    {
        value: "Podiatrist Visit",
        name: "Podiatrist Visit",
    },
    {
        value: "Massage",
        name: "Massage",
    },
    {
        value: "Gym",
        name: "Gym",
    },
    {
        value: "Aesthetician",
        name: "Aesthetician",
    },
    {
        value: "Pool",
        name: "Pool",
    },
    {
        value: "Hot Tub",
        name: "Hot Tub",
    },
    {
        value: "Sauna",
        name: "Sauna",
    },
];

export const activitiesTypes = [
    {
        value: "None",
        name: "None",
    },
    {
        value: "Excursions",
        name: "Excursions",
    },
    {
        value: "Bocce Ball",
        name: "Bocce Ball",
    },
    {
        value: "Putting Greens",
        name: "Putting Greens",
    },
    {
        value: "Golf Course",
        name: "Golf Course",
    },
    {
        value: "Pool Table",
        name: "Pool Table",
    },
    {
        value: "Tennis",
        name: "Tennis",
    },
    {
        value: "Pickle Ball",
        name: "Pickle Ball",
    },
    {
        value: "Wellness Program",
        name: "Wellness Program",
    },
    {
        value: "Fitness Trainer",
        name: "Fitness Trainer",
    },
];

export const diningTypes = [
    {
        value: "None",
        name: "None",
    },
    {
        value: "Buffet",
        name: "Buffet",
    },
    {
        value: "Plated",
        name: "Plated",
        help: "Plated food: The art of arranging, decorating, and presenting food in a way that improves its aesthetic appeal to the diner when served.        ",
    },
    {
        value: "Tray-Service-In-Room (Elected)",
        name: "Tray-Service-In-Room (Elected)",
    },
    {
        value: "Tray-Service-In-Room (Need-based)",
        name: "Tray-Service-In-Room (Need-based)",
    },
];

export const phoneTypes = [
    {
        value: "phone",
        name: "Phone",
    },
    {
        value: "fax",
        name: "Fax",
    },
    {
        value: "mobile",
        name: "Mobile",
    },
    {
        value: "work",
        name: "Work",
    },
    {
        value: "home",
        name: "Home",
    },
    {
        value: "other",
        name: "Other",
    },
];

export const templateTypes = [
    {
        value: "Apartment",
        name: "Apartment",
    },
    {
        value: "Cottage",
        name: "Cottage",
    },
    {
        value: "Townhouse",
        name: "Townhouse",
    },
];

export const roomTypes = [
    {
        value: "Studio",
        name: "Studio",
    },
    {
        value: "1 Bedroom",
        name: "1 Bedroom",
    },
    {
        value: "1 Bedroom w/den",
        name: "1 Bedroom w/den",
    },
    {
        value: "2 Bedroom",
        name: "2 Bedroom",
    },
];

export const roomTypeTypes = [
    {
        value: "Private Room",
        name: "Private Room",
    },
    {
        value: "Shared Room",
        name: "Shared Room",
    },
];

export const bathroomTypes = [
    {
        value: "1 Bathroom",
        name: "1 Bathroom",
    },
    {
        value: "1 1/2 Bathroom",
        name: "1 1/2 Bathroom",
    },
    {
        value: "2 Bathrooms",
        name: "2 Bathrooms",
    },
];

export const bathTypes = [
    {
        value: "Shower",
        name: "Shower",
    },
    {
        value: "Shower/Tub",
        name: "Shower/Tub",
    },
    {
        value: "Shower Enclosure",
        name: "Shower Enclosure",
    },
    {
        value: "Roll-in Shower",
        name: "Roll-in Shower",
    },
    {
        value: "Shower Seat",
        name: "Shower Seat",
    },
    {
        value: "Step-in Shower",
        name: "Step-in Shower",
    },
    {
        value: "Jack & Jill",
        name: "Jack & Jill",
    },
    {
        value: "Shared",
        name: "Shared",
    },
];

export const sinkTypes = [
    {
        value: "Single",
        name: "Single",
    },
    {
        value: "Double",
        name: "Double",
    },
];

export const kitchenTypes = [
    {
        value: "None",
        name: "None",
    },
    {
        value: "Full Kitchen",
        name: "Full Kitchen",
    },
    {
        value: "Kitchenette",
        name: "Kitchenette",
    },
];

export const microwaveTypes = [
    {
        value: "None",
        name: "None",
    },
    {
        value: "Built-In",
        name: "Built-In",
    },
    {
        value: "Free Standing",
        name: "Free Standing",
    },
];

export const kitchenSinkTypes = [
    {
        value: "1 Compartment LG",
        name: "1 Compartment LG",
    },
    {
        value: "1 Compartment SM",
        name: "1 Compartment SM",
    },
    {
        value: "2 Compartment",
        name: "2 Compartment",
    },
];

export const kitchenFridgeSizeTypes = [
    {
        value: "Full Size",
        name: "Full Size",
    },
    {
        value: "Dormitory Size",
        name: "Dormitory Size",
    },
];

export const kitchenFridgeOptionsTypes = [
    {
        value: "Freezer",
        name: "Freezer",
    },
    {
        value: "Ice Maker",
        name: "Ice Maker",
    },
    {
        value: "Water Dispenser",
        name: "Water Dispenser",
    },
];

export const washerDryerTypes = [
    {
        value: "None",
        name: "None",
    },
    {
        value: "Stacking - In Unit",
        name: "Stacking - In Unit",
    },
    {
        value: "Side by Side - In Unit",
        name: "Side by Side - In Unit",
    },
    {
        value: "All-in-one - In Unit",
        name: "All-in-one - In Unit",
    },
    {
        value: "Community Laundry Room",
        name: "Community Laundry Room",
    },
    {
        value: "Staff Managed Laundry",
        name: "Staff Managed Laundry",
    },
];

export const otherTypes = [
    {
        value: "Patio",
        name: "Patio",
    },
    {
        value: "Balcony",
        name: "Balcony",
    },
];

export const descriptorsTypes = [
    {
        value: "Near elevator",
        name: "Near elevator",
    },
    {
        value: "Near dining",
        name: "Near dining",
    },
    {
        value: "Near activities",
        name: "Near activities",
    },
    {
        value: "Private entry",
        name: "Private entry",
    },
    {
        value: "Garage access",
        name: "Garage access",
    },
    {
        value: "Recently updated",
        name: "Recently updated",
    },
    {
        value: "New paint",
        name: "New paint",
    },
    {
        value: "New construction",
        name: "New construction",
    },
    {
        value: "Vinyl wood flooring",
        name: "Vinyl wood flooring",
    },
    {
        value: "Carpet",
        name: "Carpet",
    },
    {
        value: "Fireplace",
        name: "Fireplace",
    },
    {
        value: "Luxury finishes",
        name: "Luxury finishes",
    },
    {
        value: "Upgraded appliances",
        name: "Upgraded appliances",
    },
    {
        value: "Thermostat",
        name: "Thermostat",
    },
    {
        value: "Air conditioning",
        name: "Air conditioning",
    },
    {
        value: "Premium view",
        name: "Premium view",
    },
    {
        value: "Wifi Enabled",
        name: "Wifi Enabled",
    },
    {
        value: "Basic Cable Included",
        name: "Basic Cable Included",
    },
    {
        value: "Pet friendly",
        name: "Pet friendly",
    },
    {
        value: "ADA features",
        name: "ADA features",
    },
    {
        value: "Outdoor space",
        name: "Outdoor space",
    },
    {
        value: "Personal Storage",
        name: "Personal Storage",
    },
];

export const imageTypes = [
    {
        value: "Community Logo",
        name: "Community Logo (Premium Plan Only)",
    },
    {
        value: "Main",
        name: "Main",
    },
    {
        value: "Community",
        name: "Community",
    },
    {
        value: "Amenity",
        name: "Amenity",
    },
    {
        value: "Activity",
        name: "Activity",
    },
    {
        value: "Dining",
        name: "Dining",
    },
    {
        value: "Floor Plan",
        name: "Floor Plan",
    },
    {
        value: "Unit",
        name: "Unit",
    },
    {
        value: "Bathroom",
        name: "Bathroom",
    },
    {
        value: "Kitchen",
        name: "Kitchen",
    },
];

export const secondPersonFeeTypes = [
    {
        value: "Second Person Fee Required",
        name: "Second Person Fee Required",
    },
    {
        value: "Second Person Fee Not Required",
        name: "Second Person Fee Not Required",
    },
    {
        value: "Second Person/Fees Determined by Unit",
        name: "Second Person/Fees Determined by Unit",
    },
    {
        value: "No Second Person Allowed",
        name: "No Second Person Allowed",
    },
];

export const monthlyPetFeeTypes = [
    {
        value: "Monthly Pet Fee Included in Rent",
        name: "Monthly Pet Fee Included in Rent",
    },
    {
        value: "Monthly Pet Fee Not Included in Rent",
        name: "Monthly Pet Fee Not Included in Rent",
    },
    {
        value: "Monthly Pet Fee Determined by Unit",
        name: "Monthly Pet Fee Determined by Unit",
    },
    {
        value: "No Pets Allowed",
        name: "No Pets Allowed",
    },
];

export const petDepositTypes = [
    {
        value: "Pet Deposit Required",
        name: "Pet Deposit Required",
    },
    {
        value: "Pet Deposit Not Required",
        name: "Pet Deposit Not Required",
    },
    {
        value: "Pet Deposit Determined by Unit",
        name: "Pet Deposit Determined by Unit",
    },
    {
        value: "No Pets Allowed",
        name: "No Pets Allowed",
    },
];

export const parkingFeeTypes = [
    {
        value: "No Parking Fees Required",
        name: "No Parking Fees Required",
    },
    {
        value: "Only Paid Parking Available",
        name: "Only Paid Parking Available",
    },
    {
        value: "Paid Parking Optional",
        name: "Paid Parking Optional",
    },
];

export const yesNoTypes = [
    {
        value: "Yes",
        name: "Yes",
    },
    {
        value: "No",
        name: "No",
    },
];

export const states = [
    {
        name: "Alabama",
        value: "AL",
    },
    {
        name: "Alaska",
        value: "AK",
    },
    {
        name: "American Samoa",
        value: "AS",
    },
    {
        name: "Arizona",
        value: "AZ",
    },
    {
        name: "Arkansas",
        value: "AR",
    },
    {
        name: "California",
        value: "CA",
    },
    {
        name: "Colorado",
        value: "CO",
    },
    {
        name: "Connecticut",
        value: "CT",
    },
    {
        name: "Delaware",
        value: "DE",
    },
    {
        name: "District Of Columbia",
        value: "DC",
    },
    {
        name: "Federated States Of Micronesia",
        value: "FM",
    },
    {
        name: "Florida",
        value: "FL",
    },
    {
        name: "Georgia",
        value: "GA",
    },
    {
        name: "Guam",
        value: "GU",
    },
    {
        name: "Hawaii",
        value: "HI",
    },
    {
        name: "Idaho",
        value: "ID",
    },
    {
        name: "Illinois",
        value: "IL",
    },
    {
        name: "Indiana",
        value: "IN",
    },
    {
        name: "Iowa",
        value: "IA",
    },
    {
        name: "Kansas",
        value: "KS",
    },
    {
        name: "Kentucky",
        value: "KY",
    },
    {
        name: "Louisiana",
        value: "LA",
    },
    {
        name: "Maine",
        value: "ME",
    },
    {
        name: "Marshall Islands",
        value: "MH",
    },
    {
        name: "Maryland",
        value: "MD",
    },
    {
        name: "Massachusetts",
        value: "MA",
    },
    {
        name: "Michigan",
        value: "MI",
    },
    {
        name: "Minnesota",
        value: "MN",
    },
    {
        name: "Mississippi",
        value: "MS",
    },
    {
        name: "Missouri",
        value: "MO",
    },
    {
        name: "Montana",
        value: "MT",
    },
    {
        name: "Nebraska",
        value: "NE",
    },
    {
        name: "Nevada",
        value: "NV",
    },
    {
        name: "New Hampshire",
        value: "NH",
    },
    {
        name: "New Jersey",
        value: "NJ",
    },
    {
        name: "New Mexico",
        value: "NM",
    },
    {
        name: "New York",
        value: "NY",
    },
    {
        name: "North Carolina",
        value: "NC",
    },
    {
        name: "North Dakota",
        value: "ND",
    },
    {
        name: "Northern Mariana Islands",
        value: "MP",
    },
    {
        name: "Ohio",
        value: "OH",
    },
    {
        name: "Oklahoma",
        value: "OK",
    },
    {
        name: "Oregon",
        value: "OR",
    },
    {
        name: "Palau",
        value: "PW",
    },
    {
        name: "Pennsylvania",
        value: "PA",
    },
    {
        name: "Puerto Rico",
        value: "PR",
    },
    {
        name: "Rhode Island",
        value: "RI",
    },
    {
        name: "South Carolina",
        value: "SC",
    },
    {
        name: "South Dakota",
        value: "SD",
    },
    {
        name: "Tennessee",
        value: "TN",
    },
    {
        name: "Texas",
        value: "TX",
    },
    {
        name: "Utah",
        value: "UT",
    },
    {
        name: "Vermont",
        value: "VT",
    },
    {
        name: "Virgin Islands",
        value: "VI",
    },
    {
        name: "Virginia",
        value: "VA",
    },
    {
        name: "Washington",
        value: "WA",
    },
    {
        name: "West Virginia",
        value: "WV",
    },
    {
        name: "Wisconsin",
        value: "WI",
    },
    {
        name: "Wyoming",
        value: "WY",
    },
];
